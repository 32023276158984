import { Language } from '@/translation/locales';

export type QuestionCommon = {
  id: string;
  content: string;
  explanation: string;
  selected: boolean;
  finished: boolean;
  score: number;
  articles: string;
  language: Language;
  questionType: QuestionType;
  translations?: Array<NotationQuestion | MultipleQuestion | BinaryQuestion>;
};

export type QuestionType = 'notation' | 'multipleChoice' | 'binaryChoice';
export enum QuestionTypeEnum {
  notation = 'notation',
  multipleChoice = 'multipleChoice',
  binaryChoice = 'binaryChoice',
}

export type NotationQuestion = QuestionCommon & {
  restart: string | null;
  cautionCounter: number;
  sendOffCounter: number;
};

export type MultipleQuestion = QuestionCommon & {
  A: string;
  B: string;
  C: string;
  D: string;
  correctAnswer: string; // "C,D"
};

export type BinaryQuestion = QuestionCommon & {
  correctAnswer: boolean;
};

export type Question = NotationQuestion | MultipleQuestion | BinaryQuestion;
